import React, { useEffect } from 'react'
import useAuth from '../hooks/useAuth'
import { Link, useNavigate } from 'react-router-dom'
import { useGetUsersQuery } from '../features/users/usersApiSlice'
import SubTemplate from './SubTemplate'
import useTitle from '../hooks/useTitle'



const Subs = () => {

  const navigate = useNavigate()

  const { username, isSVL, sub } = useAuth()
  useTitle(`Volleysub: ${username}`)

  useEffect(() => {
      if(isSVL) {
          navigate('/dash/profile', { replace: true})
      }
  })

  const {
    data: users
} = useGetUsersQuery('usersList', {
    pollingInterval: 60000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true
})
// eslint-disable-next-line
const { ids } = users

const editOrBecomeSub = () => {
  if (sub) {
    return "View My Sub Info"
  } else {
    return "Become a Sub"
  }
}

const content = () => {
  const { ids } = users
  const tableContent = ids?.length && ids.map(userId => <SubTemplate key={userId} userId={userId} />)
  if (!ids) {
    return <td>Issue accessing player info. Please log out and log back in.</td>
  } else {
    return tableContent
  }
}

// eslint-disable-next-line
const tableContent = ids?.length && ids.map(userId => <SubTemplate key={userId} userId={userId} />)

  return (
    <>
    <h2 className='league-title'>Subs</h2>

    <div className="sub-links">
      <p className="sub-links">Request a sub for your next game or join our list of subs ready to play!</p>
    </div> 

    <h3 className='player-dash-headers'>Sub Actions</h3>

    <div className="sub-links">
      <p className="sub-links"><Link to="/dash/playerinfo">{editOrBecomeSub()}</Link></p>
      <p className="sub-links"><Link to="/dash/notes/new">Create Sub Request</Link></p>
      <p className="sub-links"><Link to="/dash/notes">Open Sub Requests</Link></p>
    </div> 

    <div className='available-subs'>
      <h3 className='player-dash-headers'>Available Subs</h3>

      <table className='available-sub-table'>
      <div className='sub-table-header-group'>
      <thead>
        <tr>
          <th className='sub-table-header'>First Name</th>
          <th className='sub-table-header'>Last Name</th>
          <th className='sub-table-header visible'>Gender</th>
          <th className='sub-table-header'>Hitter Rating</th>
          <th className='sub-table-header'>Passer Rating</th>
          <th className='sub-table-header visible'>Setter Rating</th>
        </tr>
        </thead>
        <tbody>
          {content()}
        </tbody>
        </div>
      </table>
    </div>
    </>
  )
}



export default Subs